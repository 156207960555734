.container {
  height: calc(100% - var(--dashboard-header-height));
  overflow: auto;
  overflow-x: hidden;
}

.inner {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  padding: 0 24px;
}
