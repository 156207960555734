@import '~@astrid/components/src/scss/utils';

.studyTimeBeforeUserCreation {
  background-color: $very-light-grey;
}

.leftButton {
  float: left;
}

.rightButton {
  float: right;
}

.container {
  margin: 25px 0 0;

  table {
    border-radius: 10px;
    border-collapse: collapse;
    width: 100%;
    table-layout: fixed;

    thead {
      tr {
        border: 0;
        &:first-child {
          th {
            border: 0;
          }
        }
      }
    }

    td,
    th,
    tr {
      border: 2px solid $light-grey;
    }

    td,
    th {
      padding: 16px;
      text-align: left;
    }
  }

  thead {
    tr {
      th {
        font-family: $workSansFont;
        font-size: 18px;
        font-weight: 800;
        font-stretch: normal;
        font-style: normal;
        line-height: 50px;
        letter-spacing: normal;
        color: $black;
      }
    }
  }

  tbody {
    th,
    td {
      font-family: $lexendFont;
      font-size: 16px;
      color: $black;
    }

    a {
      color: $cobalt-blue;

      &:hover {
        text-decoration: underline;
      }
    }

    tr {
      &:hover {
        background-color: $very-light-grey;
      }
    }

    td {
      height: 56px;
      padding: 0 16px;
      user-select: none;
      position: relative;
    }
  }
}
