@import '~@astrid/components/src/scss/utils';

.progressIndicators {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
}

.progressSegment {
  & > p {
    font-family: $lexendFont;
    font-size: 18px;
    margin-top: 0;
    line-height: 1.61;
    color: $black;
    text-align: center;

    span {
      color: rgba(10, 10, 10, 0.5);
      margin-left: 5px;
    }
  }
}

.progressChart {
  width: 50%;
  margin: 20px 0 0;
}

.effortCombineChart {
  margin: 20px 0 0;
}

.effortChart {
  width: 50%;
  margin: 20px 0 0;
}

.progressContent {
  width: 120px;
  height: 120px;
  font-size: 48px;
  border-radius: 100%;
  color: white;
  font-family: $workSansFont;
  font-weight: 800;
  line-height: 1;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  small {
    font-family: $lexendFont;
    font-weight: normal;
    font-size: 14px;
    margin-bottom: -10px;
  }

  &Small {
    @extend .progressContent;
    width: 150px !important;
    height: 150px !important;
    font-size: 32px !important;
    small {
      font-size: 12px;
      margin-bottom: -5px;
      &:empty {
        display: none;
      }
    }
  }
}

.progressGoal {
  position: absolute;
  padding: 2px;
  top: 0;
  margin-left: 3px;
  color: white;
  font-size: 18px;
  font-weight: 800;
}
