.container {
  width: 100%;
  max-width: 1440px;
  align-self: center;
  padding: 32px 0;
}

.modalControls {
  display: flex;
  flex-direction: row;
  button {
    margin: 0 8px;
  }
}

.header {
  margin-bottom: 16px;

  & > * {
    padding: 16px;
  }

  &__block {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: fit-content;
    h3 {
      margin: 0;
    }
    p {
      margin: 0;
    }

    &__edit {
      width: 18px;
      height: 18px;
      fill: lightgray;
      margin-left: 12px;
      cursor: pointer;
      &:hover {
        fill: black;
      }
    }
  }

  &__confirmationButtons {
    display: flex;
    flex-direction: row;
    margin-left: 16px;
  }
}

.uploadErrorsModal {
  li {
    margin: 8px 0;
    font-size: 18px;
    color: red;
  }

  ul {
    margin-bottom: 32px;
  }

  p {
    margin-bottom: 32px;
  }
}

.message {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  color: grey;

  .spinner {
    color: grey;
  }
}
