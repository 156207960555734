.wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.select {
  margin: 0 8px 0 0;
  max-width: 50%;
}

.logout {
  margin-top: 16px;
}
