@import '~@astrid/components/src/scss/utils';

.container {
  overflow: auto;
  h2 {
    text-align: center;
  }
}

.uploadStep {
  width: 100%;
  margin-bottom: 40px;
  h4 {
    margin: 0;
  }

  & > div {
    display: flex;
    flex-direction: row;
    a {
      @include chip-styling(true);
      margin-right: 4px;
    }
  }
}

.uploadControls {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  button {
    margin: 0 10px;

    &:first-child {
      background-color: transparent;
      border: none;
      padding: 0;
      height: fit-content;
      font-size: 16px;
    }
  }
}

.xlsxInput {
  display: flex;
  flex-direction: row;
  width: fit-content;
  p {
    color: black;
    border: none;
    padding: 8px;
    border: 1px solid lightgray;
    border-radius: 4px;
    margin: 0 16px 0 0;
  }
  span {
    @include chip-styling(true);
  }
}
