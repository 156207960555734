.recover-password {
  &__buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 24px;

    button {
      margin: 0 10px;
    }
  }
}
