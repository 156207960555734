@import '~@astrid/components/src/scss/utils';

.root {
  width: 100%;
  border-bottom: 2px solid rgba(190, 194, 199, 0.2);
  height: var(--dashboard-header-height);
  background: #ffffff;
  display: flex;
  flex-direction: row;
  align-items: center;
  nav {
    margin-left: auto;
    > button {
      margin: 0 5px;
    }
  }
}

.container {
  width: 100%;
  height: 100%;
  max-width: 1488px;
  padding: 0 24px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.controls {
  display: flex;
  flex-direction: row;
}

.logo {
  width: 106px;
  max-height: 100%;
}

.close {
  height: 2.5rem;
  width: 2.5rem;
  padding: 0.25rem;
  fill: $cobalt-blue;
  cursor: pointer;
  border-radius: 100px;
  transition: 250ms all;
  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
}

.link {
  @include chip-styling;
  margin-left: 1rem;

  &.active {
    background-color: darken($blue-grey, 8%);
  }
}
