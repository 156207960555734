@import '~@astrid/components/src/scss/utils';

.container {
  margin: 25px 0 0;

  table {
    border: 2px solid $light-grey;
    border-radius: 10px;
    border-collapse: collapse;
    width: 100%;

    td,
    th,
    tr {
      border: 2px solid $light-grey;
    }

    td,
    th {
      padding: 16px;
      text-align: left;
    }
  }

  input {
    font: inherit;
    width: 100%;
    margin: 0;
    display: block;
    padding: 6px 0 7px;
    min-width: 0;
    background: none;
    border: none !important;
    outline: none;
  }

  thead {
    tr {
      &:first-child {
        th {
          font-family: $workSansFont;
          font-size: 18px;
          font-weight: 800;
          font-stretch: normal;
          font-style: normal;
          line-height: 50px;
          letter-spacing: normal;
          color: $black;

          &:nth-of-type(2) {
            background-color: $blue-grey;
          }

          &:nth-of-type(3) {
            background-color: rgba($topaz, 0.1);
          }
        }
      }

      &:last-child {
        th,
        p {
          font-family: $lexendFont;
          font-size: 16px;
          color: $black;
          white-space: nowrap;
          text-align: left;
          font-weight: normal;
        }
      }
    }
  }

  tbody {
    th,
    td {
      font-family: $lexendFont;
      font-size: 16px;
      color: $black;
    }

    a {
      color: $cobalt-blue;
      text-decoration: underline;
    }

    tr {
      &:hover {
        background-color: $very-light-grey;
      }
    }

    td {
      height: 56px;
      padding: 0 16px;
      user-select: none;
      position: relative;
      &.hasEdit {
        background-color: $very-light-grey;
        color: rgba(10, 10, 10, 0.3);
      }
    }

    .total td {
      font-weight: bold;
      background-color: $blue-grey;
    }
  }
}

.inputWithUnits {
  display: flex;
  flex-direction: row;
  align-items: center;
  span {
    margin-left: 4px;
  }
}

.cellActions {
  float: right;
  display: flex;
  flex-direction: row;
  margin: 0 -5px;

  button {
    margin: 0 5px;
  }
}

.editMode {
  tbody {
    th,
    td {
      &:not(.isEdited) {
        opacity: 0.5;
      }
    }
  }
}

.columnComment {
  color: $grey;
}

.message {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  color: grey;

  .spinner {
    color: grey;
  }
}
