@import '~@astrid/components/src/scss/_utils';

.container {
  width: 100%;
  border-radius: 4px;
  border: 2px solid $light-grey;
  border-collapse: collapse;
}

.row {
  th {
    text-align: start;
    border: 2px solid $light-grey;
    h4 {
      margin: 16px;
    }
  }

  &:hover {
    td {
      background-color: rgba($cobalt-blue, 0.1);
    }
  }

  td {
    text-align: start;
    font-family: 'Lexend', sans-serif;
    font-size: 16px;
    padding: 16px;
    border: 2px solid $light-grey;

    a {
      color: $cobalt-blue;
      text-decoration: underline;
    }

    & > div {
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      svg {
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}
