@import '~@astrid/components/src/scss/utils';

$sheet-preview-header-height: 64px;

.container {
  width: 100%;
  height: 100%;
  overflow: auto;
}

.header {
  width: 100%;
  height: $sheet-preview-header-height;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: $blue-grey;
  padding: 8px;
  & > div {
    display: flex;
    align-items: center;
    width: fit-content;
    flex-direction: row;

    & > button:first-child {
      margin-right: 8px;
      display: flex;
      align-items: center;
      svg {
        margin-right: 6px;
        margin-bottom: -1px;
      }

      &:disabled {
        svg {
          fill: grey !important;
        }
      }
    }
  }
  h4 {
    margin: 0 0 0 12px;
  }
}

div.spinner {
  color: grey;
  margin-right: 6px;
}

.verticalCell {
  display: flex;
  flex-direction: column;
  margin: 0;
}

.card {
  padding: 16px;
  height: calc(100vh - #{$sheet-preview-header-height});
  overflow: auto;

  & > * {
    padding: 0;
  }
}

.table tr:last-child td {
  border-bottom: 0;
}
