@import '~@astrid/components/src/scss/utils';

.studyTimeLow {
  background-color: rgba($rosy-pink, 0.6);
}

.studyTimeMiddle {
  background-color: rgba($maize, 0.6);
}

.studyTimeHigh {
  background-color: rgba($success-green, 0.6);
}

.leftButton {
  float: left;
}

.rightButton {
  float: right;
}
