@import '~@astrid/components/src/scss/utils';

.forgot {
  cursor: pointer;
  margin: 0;
  margin-top: 18px;
}

.contact {
  cursor: pointer;
  position: absolute;
  bottom: 10px;
}
