@import '~@astrid/components/src/scss/utils';

.book-factory {
  width: calc(100% + 48px);
  margin-left: -24px;
  height: 100%;
  display: flex;
  flex-grow: 1;
  position: relative;
  left: 0;

  &__navigation {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    pointer-events: none;

    svg {
      width: 80px;
      height: 100%;
      padding: 0 15px;
      cursor: pointer;
      transition: 300ms all;
      pointer-events: all;

      &:hover {
        background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4));
      }

      &:first-of-type {
        transform: rotate(180deg);
      }
    }
  }

  &__progress {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 8px;
    background-color: lighten($cobalt-blue, 60%);
    z-index: 10;

    & > div {
      height: 100%;
      background-color: $cobalt-blue;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      transition: 800ms all;
    }
  }
}
