@import '~@astrid/components/src/scss/utils';

.container {
  width: 550px;
  min-height: 100px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  overflow: auto;

  form {
    width: 100%;
  }
}

.wrapper {
  display: flex;
  width: 100%;
  max-width: 700px;
  flex-direction: column;
  align-items: center;
}

.confirm {
  margin-top: 16px;
}

.questionText {
  margin: 30px 0;
}

.cefrTable {
  label span {
    font-weight: bold;
  }

  td {
    text-align: left;
  }

  border-width: 2px;
  border-color: red;
}
