@import '~@astrid/components/src/scss/utils';

.root {
  width: 100%;
  min-height: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 40px 0;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  & > div {
    flex: 1 0 auto;
  }
}

.headline {
  display: flex;
  flex-direction: row;
  align-items: center;

  img {
    border-radius: 100%;
    margin-right: 15px;
  }

  h2,
  p {
    margin: 0;
  }
}

.filtersContainer {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;

  & > div[data-role='wrapper'] {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
  }
}

.toggle {
  margin-right: 16px;
}

.classSelect {
  margin: 0 0 16px;
}

.card {
  margin-top: 24px;
  width: 50%;
}
