@import '~@astrid/components/src/scss/utils';

.container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;

  & > div {
    height: auto;
    width: calc(50% - 16px);
  }
}

.card {
  margin-bottom: 24px;
}
